<template>
  <div>

    <!-- Content -->
    <div class="main-container pb-5" v-lazy-container="{ selector: 'img' }" :id=sellerID>
      <div class="container">
        <!--title-->
        <h2 class="font-weight-bold">{{ $t('meet_the_sellers_article.' +  sellerID + '.title1') }}, {{ $t('meet_the_sellers_article.' +  sellerID + '.title2') }}</h2>
        <!--seller-headline-->
        <div class="seller-headline d-flex flex-column-reverse flex-md-row justify-content-between">
          <div class="d-flex flex-column align-items-center justify-content-center font-weight-bold pr-md-4">
            <p class="text-center w-100">{{ $t('meet_the_sellers_article.' +  sellerID + '.header.copy1') }}</p>
            <p class="text-center w-100 mb-2 mb-md-0">{{ $t('meet_the_sellers_article.' +  sellerID + '.header.copy2') }}</p>
          </div>
          <img :data-src="require('@/assets/MeetTheSellers/' +  sellerID + '.jpg')" class="mb-4 mb-md-0">
        </div>

        <!--how_it_began-->
        <div class="row mb-4">
          <h3 class="font-weight-bold mx-auto mb-4">{{ $t('meet_the_sellers_article.how_it_began') }}</h3>
          <p class="text-center w-100 px-3 px-md-0">{{ $t('meet_the_sellers_article.' +  sellerID + '.how_it_began.copy1') }}</p>
          <p v-if="$t('meet_the_sellers_article.' +  sellerID + '.how_it_began.copy2') != 'meet_the_sellers_article.' +  sellerID + '.how_it_began.copy2'" class="text-center w-100 px-3 px-md-0">{{ $t('meet_the_sellers_article.' +  sellerID + '.how_it_began.copy2') }}</p>
        </div>

        <!--why_anytask-->
        <div class="row mb-4">
          <h3 class="font-weight-bold mx-auto mb-4">{{ $t('meet_the_sellers_article.why_anytask') }}</h3>
          <p class="text-center w-100 mb-4 px-3 px-md-0">{{ $t('meet_the_sellers_article.' +  sellerID + '.why_anytask.copy1') }}</p>
          <p v-if="$t('meet_the_sellers_article.' +  sellerID + '.why_anytask.copy2') != 'meet_the_sellers_article.' +  sellerID + '.why_anytask.copy2'" class="text-center w-100 px-3 px-md-0">{{ $t('meet_the_sellers_article.' +  sellerID + '.why_anytask.copy2') }}</p>
          <p v-if="$t('meet_the_sellers_article.' +  sellerID + '.why_anytask.copy3') != 'meet_the_sellers_article.' +  sellerID + '.why_anytask.copy3'" class="text-center w-100 px-3 px-md-0">{{ $t('meet_the_sellers_article.' +  sellerID + '.why_anytask.copy3') }}</p>
        </div>

        <template v-if="hasTasks">
          <!--reviews-->
          <div class="row reviews">
          <h3 class="font-weight-bold mx-auto">{{ $t('meet_the_sellers_article.' +  sellerID + '.reviews') }}</h3>
          </div>
            <div>
            <div class="row reviews pt-4 pb-4 d-flex align-items-center" v-if="userTasksLoaded">
              <div v-for="(review, index) in reviewList" :key="index" class="col-12 col-md-4 mb-3 mb-md-0">
                <p v-if="review.title" class="testimonial mb-0">&ldquo;{{ review.title }}&rdquo;</p>
                <p class="testimonial mb-0">&ldquo;{{ review.message }}&rdquo;</p>
                <div class="stars d-flex flex-row w-100 justify-content-center my-2">
                  <span v-if="review.rating >= 1" class="fa-solid fa-star"></span>
                  <span v-if="review.rating >= 2" class="fa-solid fa-star"></span>
                  <span v-if="review.rating >= 3" class="fa-solid fa-star"></span>
                  <span v-if="review.rating >= 4" class="fa-solid fa-star"></span>
                  <span v-if="review.rating >= 5" class="fa-solid fa-star"></span>
                </div>
                <p class="author font-italic d-inline-flex position-relative mb-2 pt-3">{{review.author_user.username}}</p>
              </div>
            </div>
            <div class="loader d-flex align-items-center justify-content-center" v-else>
              <b-spinner small label="Small Spinner"></b-spinner>
            </div>
          </div>

          <!--tasks-->
          <div class="row userTasks">
          <h3 class="font-weight-bold mt-3 mb-4 mx-auto">{{ $t('meet_the_sellers_article.' +  sellerID + '.button') }}</h3>
          </div>
            <div class="pb-4 pb-lg-5">
            <div class="container featured-tasks" v-if="userTasksLoaded">
              <div class="row mt-2">
                <carousel :nav="true" :dots="false" :items="4" :responsive="{0:{items:1, dots:true},576:{items:2, dots:true}, 768:{items:3},992:{items:4}}" :margin="20">
                  <div v-for="(task, index) in activeTasks" v-bind:key="index">
                    <Card
                      :isFeaturedTask="true"
                      :carouselImage="task"
                      :username="sellerID"
                      :slug="task.slug"
                      :user_rating="user.review_average"
                      :user_count="user.review_count"
                      :user_online="user.last_active ? isUserOnline(user.last_active) : false"
                      :imageIcon="user.photo_path"
                      :description="task.title"
                      :rating="task.review_average"
                      :count="task.review_count"
                      :price="task.packages[0].price"
                      :deliveryTime="task.packages[0].delivery"
                      :hash="task.hash"
                      :select="task.favourite"
                      :position="index + 1"
                      :sellerInfo="user"
                      :location="'meet-the-seller-article-' + sellerID"
                      :primaryCategory="task.categories[0].slug"
                      :isHeroTask="task.hero"
                      class="h-100 mt-0"/>
                  </div>
                </carousel>
              </div>
            </div>
            <div class="loader d-flex align-items-center justify-content-center" v-else>
              <b-spinner small label="Small Spinner"></b-spinner>
            </div>
          </div>
        </template>

        <!--buttons-->
        <div class="d-flex d-flex flex-column flex-md-row justify-content-center buttons">
          <div class="px-3 mb-3 mb-md-0" v-if="userTasksLoaded">
            <router-link :to="'../' + sellerID">
              <button class="btn btn-primary d-flex w-100 text-center justify-content-center">
                {{ $t('meet_the_sellers_article.view_all_tasks_reviews') }}
              </button>
            </router-link>
          </div>
          <div class="px-3">
            <router-link to="../meet-the-sellers">
              <button class="btn btn-outline-primary d-flex w-100 text-center justify-content-center">
                {{ $t('meet_the_sellers_article.view_another_seller') }}
              </button>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService'
import Card from '../../components/Task/Card'
import carousel from 'v-owl-carousel'
import helper from '../../helper'

export default {
  name: 'MeetTheSellersArticle',
  data () {
    return {
      sellerID: this.$route.params.sellerId,
      searchInput: '',
      user: '',
      userTasks: [],
      userReviews: [],
      userTasksLoaded: false,
      hasTasks: false,
      limit: 3
    }
  },
  components: {
    Card,
    carousel
  },
  computed: {
    activeTasks: function () {
      return this.userTasks.filter(function (t) {
        return t.status === 1
      })
    },
    reviewList () {
      return this.limit ? this.userReviews.slice(0, this.limit) : this.userReviews
    }
  },
  mounted () {
    this.getTasksByUser(this.sellerID)
  },
  methods: {
    onSubmitSearch () {
      if (this.searchInput.length > 0) {
        this.searchInput = this.searchInput.replace(/[^\w\s+]/gi, '')
      }
      this.$router.push({ name: 'search', query: { terms: this.searchInput } })
    },
    getTasksByUser () {
      ApiService.getTasksByUser(this.sellerID).then(response => {
        this.userTasks = response.data.data.gigs.slice(0, 12)
        this.user = response.data.data.user
        this.userReviews = response.data.data.user.reviews
        if (this.userTasks.length > 0) {
          this.userTasksLoaded = true
          this.hasTasks = true
        }
      }).catch(() => {
      })
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    }
  }
}
</script>

<style lang="scss" scoped>
#content {
  background: white;
  h2 {
    margin: 2rem 0 4rem 0;
  }
  .main-container {
    background: url('~@/assets/MeetTheSellers/brand-gradient.png');
    background-repeat: no-repeat;
    background-size: 250%;
    background-position: top center;
    .loader {
      min-height: 260px;
    }
    .seller-headline {
      background: white;
      box-shadow: $connecting-btn-shadow;
      margin: 4rem 0rem;
      padding: 1.25rem;
      border-radius: 10px;
      div {
        flex: 1;
      }
      img {
        border-radius: 10px;
        width: 100%;
        flex: 1;
        object-fit: cover;
      }
    }
    .buttons {
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

@include md-up() {
  #content {
    h2 {
      margin: 3.5rem 0 5.5rem 0;
    }
    .main-container {
      background-size: 110%;
      .seller-headline {
        img {
          width: 50%;
        }
      }
    }
  }
}

@include lg-up() {
  #content {
    .main-container {
      .seller-headline {
        margin: 4rem 8rem;
        img {
          max-width: 480px;
        }
      }
    }
  }
}

.owl-carousel {
  .owl-stage {
    height: auto;
  }
  .owl-item {
    min-height: 100%;
    height: 100%;
    > div {
      height: 100%;
    }
  }
  .owl-nav {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: -1;
  }
}

.userTasks {
  .card {
    .review-and-price {
      background: transparent;
    }
  }
  .owl-carousel {
    .owl-nav {
      button.owl-prev, button.owl-next {
        position: absolute;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0 !important;
        background-color: transparent;
        top: calc(50% - 30px);
      }
      button.owl-prev {
        left: -30px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23222' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
      }
      button.owl-next {
        right: -30px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23222' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
      }
      [class*='owl-']:focus {
        outline: none;
      }
      [class*='owl-']:hover {
        color: transparent;
      }
    }
  }
}

</style>

<style>
  .owl-dots {
    margin-top: .5rem;
  }
</style>
