<template>
  <div class="hero background d-flex position-relative" v-bind:class="{ homepage: homepage_banner}" id="home-hero-search">
    <div v-if="homepage_banner" :class=" { 'xmas' : new Date() < twentyTwentyOne() } " class="bg-image position-absolute w-100 h-100"></div>
    <div v-if="homepage_banner" class="bg-image position-absolute w-100 h-100"></div>
    <div v-if="homepage_banner" class="bg-image position-absolute w-100 h-100"></div>
    <div v-if="homepage_banner" class="bg-image position-absolute w-100 h-100"></div>
    <div class="container position-relative hw100 d-flex flex-column pt-5 pb-3 pb-sm-5 pl-3 pr-3 mt-2 mb-2">
      <h1 v-if="heading_one" class="text-white text-md-left text-left font-weight-bold m-0">{{ heading_one }}<br>{{ heading_two }}</h1>
      <h1 v-if="heading" class="meet-the-sellers text-white text-md-left text-center font-weight-bold py-2">{{ heading }}</h1>
      <h2 v-if="heading_sub" class="meet-the-sellers text-white text-md-left text-center d-md-block col-lg-7 p-0">{{ heading_sub }}</h2>
      <div class="refund-subcopy mt-2 text-center text-md-left">
        <i class="fa-solid fa-shield mr-1"></i><span class="white">{{ heading_guarantee }}</span>
      </div>
    </div>
    <div id="search-background-filter"></div>
  </div>
</template>

<script>
import apiService from '../../apiService'

export default {
  name: 'Hero',
  props: {
    id: String,
    homepage_banner: Boolean,
    heading: String,
    heading_sub: String,
    heading_one: String,
    heading_two: String,
    heading_guarantee: String,
    placeholder: String,
    searchBar: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      searchInput: '',
      categories: []
    }
  },
  mounted () {
    this.getCategories()
  },
  methods: {
    getCategories () {
      return apiService.getAllCategories(localStorage.getItem('language') === 'en' ? null : 'en').then((response) => {
        const responseSet = response.data['data']
        for (let x = 0; x < responseSet.length; x++) {
          this.categories.push(responseSet[x].name)
          for (let i = 0; i < responseSet[x]['child_categories'].length; i++) {
            this.categories.push(responseSet[x]['child_categories'][i].name)
          }
        }
        this.categories = [...new Set(this.categories)]
      })
    },
    twentyTwentyOne () {
      return new Date(Date.UTC(2021, 0, 1, 0, 0, 0))
    },
    showModal (data) {
      this.$bvModal.show('advanced-search')
    }
  }
}
</script>

<style scoped lang="scss">

.popular-searches {font-size: 14px; color: white;}
.popular-searches .popular-item {padding: 0 6px; margin: 2px 3px; border: 1px solid #fff; border-radius: 12px; transition: 300ms all ease-in-out; display: inline-block;}
.popular-searches .popular-item:hover {background: white; color: #007bff; cursor: pointer;}

#search-background-filter {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 100;
}

.form-control:focus {
  box-shadow: 0 0 0.2rem 0.2rem #007bff;
}

#home-hero-search {
  background: $brand-navy;
}

.refund-subcopy, .advanced-search {
  color: white;
  font-size: 14px;
}

.advanced-search {
  border: 1px solid white;
}

@include md-up {
  .advanced-search {
    border: 0;
    cursor: pointer;
  }
}

.hero {
  transition: all 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
}

.search-row {
  z-index: 110;
}

.hero h1 {
  line-height: 1;
  font-size: 40px;

  &.meet-the-sellers {
    font-size: 34px;
  }
}

.hero h2 {
  line-height: 1.2;

  &.meet-the-sellers {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .hero h1 {
    font-size: 3rem;
  }
}

.body {
  padding-top: 100px;
}

h1 {
  font-size: 34px;
  line-height: 1.3;

}

$slides: 4;
$delay-amount: 6;
$total-time: ($slides * $delay-amount);

.bannerLink-one {
  animation: linkDiv-one ($total-time + 0s) linear infinite 0s;
}

.bannerLink-one, .bannerLink-two {
  top: 0;
  right: 0;
  bottom: 0;
  width: 40%;
  z-index: -2;
  pointer-events: none;
  @include md-up {
    pointer-events: auto;
    z-index: 99;
    right: -8%;
  }
  @include lg-up {
    right: 0;
  }
}

@keyframes linkDiv-one {
  0% {
    opacity: 0;
    visibility: none;
    pointer-events: none;
  }
  3% {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  26% {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  28% {
    opacity: 0;
    visibility: none;
    pointer-events: none;
  }
  100% {
    opacity: 0;
    visibility: none;
    pointer-events: none;
  }
}

.hero.homepage > .bg-image {
  animation: imageAnimation ($total-time + 0s) linear infinite 0s;
  backface-visibility: hidden;
  background-size: cover;
  background-position: 58% center;
  background-color: $brand-navy;
  color: transparent;
  left: 0px;
  top: 0px;
  opacity: 0;
  z-index: 0;
  @include sm-up {
    background-position: 66% center;
  }
  @include md-up {
    background-position: center center;
  }

  &:nth-child(1) {
    background-image: url('~@/assets/Hero/banner-1-mobile.jpg');
    @include sm-up {
      background-image: url('~@/assets/Hero/banner-1-small.jpg');
    }
    @include md-up {
      background-image: url('~@/assets/Hero/banner-1-medium.jpg');
    }
    @include lg-up {
      background-image: url('~@/assets/Hero/banner-1-large.jpg');
    }
  }

  &:nth-child(2) {
    animation-delay: $delay-amount + 0s;
    background-image: url('~@/assets/Hero/banner-2-mobile.jpg');
    @include sm-up {
      background-image: url('~@/assets/Hero/banner-2-small.jpg');
    }
    @include md-up {
      background-image: url('~@/assets/Hero/banner-2-medium.jpg');
    }
    @include lg-up {
      background-image: url('~@/assets/Hero/banner-2-large.jpg');
    }
  }

  &:nth-child(3) {
    animation-delay: ($delay-amount * 2) + 0s;
    background-image: url('~@/assets/Hero/banner-3-mobile.jpg');
    @include sm-up {
      background-image: url('~@/assets/Hero/banner-3-small.jpg');
    }
    @include md-up {
      background-image: url('~@/assets/Hero/banner-3-medium.jpg');
    }
    @include lg-up {
      background-image: url('~@/assets/Hero/banner-3-large.jpg');
    }
  }

  &:nth-child(4) {
    animation-delay: ($delay-amount * 3) + 0s;
    background-image: url('~@/assets/Hero/banner-4-mobile.jpg');
    @include sm-up {
      background-image: url('~@/assets/Hero/banner-4-small.jpg');
    }
    @include md-up {
      background-image: url('~@/assets/Hero/banner-4-medium.jpg');
    }
    @include lg-up {
      background-image: url('~@/assets/Hero/banner-4-large.jpg');
    }
  }
}

@keyframes imageAnimation {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
  }
  8% {
    animation-timing-function: ease-out;
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

</style>
